/** @jsx jsx */
import { jsx, Box } from 'theme-ui';
import { useTranslation, Trans } from 'react-i18next';
import SEO from '../../components/seo';
import { PageRoot } from '../../data/content';
import { Fragment } from "react";
import { useTextDirection } from '../../i18n';
import { Link } from '../../components/nav';
import { ContributeAction } from '../../components/actions';

const notNowButtonStyle = {
  width: ['100%', '60%', '40%'],
  maxWidth: '210px',
  marginBottom: '14px',
  marginTop: '14px',
  color: "rgba(0,0,0,0.6)",
  backgroundColor: "#F7F4F1",
  fontSize: ["14px", "15px", "16px"],
  '&:hover': {
    textDecoration: "underline",
    color: "#FC164B"
  }
}

const ContributePage: PageRoot<{}> = ({ location: { pathname } }) => {
  const { i18n: { language }, t } = useTranslation()
  const dir = useTextDirection(language)

  const chevron = require('../../images/chevron.svg')

  return (
    <Fragment>
      <SEO
        path={pathname}
        title={'Support Progressive International'}
      />
      <Fragment>
        <Box sx={{
          px: 4,
          py: [4, 4, 6],
          top: 0,
          maxWidth: "700px",
          margin: "0px auto",
          color: "#280000"
        }}>
          <div sx={{ fontFamily: "IBM Plex Mono", fontSize: "12px", color: "#c4b4b3", display: "flex", paddingBottom: "40px", paddingTop: "35px" }}>
            <span><Trans>Your Details</Trans></span>
            <img src={chevron} sx={{ transform: "rotate(90deg)", width: "13px", marginLeft: "5px", marginRight: "5px" }} />
            <span sx={{ color: "#280000" }}><Trans>Support Us</Trans></span>
            <img src={chevron} sx={{ transform: "rotate(90deg)", width: "13px", marginLeft: "5px", marginRight: "5px" }} />
            <span sx={{ alignSelf: "center" }}><Trans>Thank You</Trans></span>
          </div>
          <div id="form-styling-container">
            <ContributeAction language={language} />
            <Link to={`/join/individual/success`}>
              <div sx={notNowButtonStyle}><Trans>Not now</Trans></div>
            </Link>
          </div>
        </Box>
      </Fragment>
    </Fragment>
  )
}

export default ContributePage